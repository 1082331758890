import React from "react"

import Layout from "../components/layout"
import { Container, Row, Col, ProgressBar, Button } from 'react-bootstrap';
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';

import nikolay from "../images/nikolay.png";
import jens from "../images/jens.png";
import threehundredsharks from "../images/300sharks_cut.png";
import opensea from "../images/opensea.svg";
import sharkspreview from "../images/300sharks_preview.gif";

const IndexPage = () => (
  <Layout>
    <SEO title="Imprint" />
    <div style={{paddingBottom: 600, paddingTop: 150}}>
        <Container>
          <h2>Imprint</h2>
          <p>
            Jens Nörtershäuser, Nikolay Dimolarov GbR
            <br></br>Degenfeldstr. 10
            <br></br>80803 München
            <br></br>Deutschland
            <br></br><br></br>info@300sharks.com
          </p>
        </Container>
    </div>
  </Layout>
);

export default IndexPage;
